import React, { useState } from 'react'
import ListItem from './list-item-component'
import { useDispatch, useSelector } from 'react-redux'
import ModalWindow from './modal-window'
import ClientDataComponent from './client-data-component'
import { Formik } from 'formik';
import { Container, Button, Form, FormGroup, Label, Input, Card, FormFeedback, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { setDate, setDeliveryCost, setDeliveryStatus, setDescription, setSubtotal, setTotal } from '../../features/cotizations/cotizationDataSlice'
import CotizationConfirmComponent from './cotization-confirm-component'
import CotizationPrintComponent from './cotization-print-component'
import PDFViewerComponent from './PDF-viewer-component'



export default function CotizationComponent() {

  const cotizationData = useSelector(state => state.cotizationData)
  const [clientCreated, setClientCreated] = useState(false)
  const [descriptionState, setDescriptionState] = useState(false)
  const [modalState, setModalState] = useState(false)
  const [cotizationCreated, setCotizationCreated] = useState(false)
  const [deliveryInt, setDeliveryInt] = useState(0)
  const dispatch = useDispatch()
  const [hasDelivery, setHasDelivery] = useState(false)
  const [pdf, setPDF] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  
  const calculateTotal = () => {
    let total = 0
    cotizationData.products.map((productItem) => {
      total = total + (productItem.product.precio_unitario * productItem.quantity)
    })

    return hasDelivery? total + deliveryInt : total
  }

  const validate = (values) => {
    const errors = {};
    return errors;
  }


  return (
    <>
      <div className='container'>
        <div className='row'>
          <h1 className="m-5" id="title"> TU COTIZACIÓN</h1>
        </div>
        {(cotizationData.products.length > 0) ?

          <div className='row'>
            <div className='col-md-8 '>
              <div className="card mx-5" style={{ 'width': '90%' }}>
                <ul className="list-group list-group-flush">

                  {cotizationData.products.map((productAux, index) => {
                    return (<li key={index} className="list-group-item"><ListItem productItem={productAux} /></li>)
                  })}
                </ul>
              </div>
            </div>
            <div className='col-md-4 '>
              <Formik
                initialValues={{
                  delivery: false,
                  description: "",
                  delivery_cost: '$ 0'
                }}
                onSubmit={async (values) => {
                  const today = new Date();
                  const now = today.toLocaleDateString('es-CO');
                  const total = calculateTotal()
                  dispatch(setDeliveryStatus(values.delivery))
                  dispatch(setDeliveryCost(deliveryInt))
                  dispatch(setDate(now))
                  dispatch(setTotal(total))
                  dispatch(setSubtotal(hasDelivery? total-deliveryInt:total))
                  setModalState(true)
                }}

                validate={validate}
              >
                {(props) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  } = props;

                  

                  const formatCurrency = (value) => {
                    const numberValue = parseFloat(value.replace(/[^\d]/g, ''));
                    setDeliveryInt(numberValue)
                    const formattedValue = new Intl.NumberFormat('es-CO', {
                      style: 'currency',
                      currency: 'COP',
                      minimumFractionDigits: 0
                    }).format(values.delivery?!!numberValue ? numberValue : '0':'0');
                    return formattedValue;
                  };

                  const handleInputChange = (event) => {
                    const { name, value } = event.target;
                    const formattedValue = formatCurrency(value);
                    handleChange({ target: { name, value: formattedValue } });
                  };

                  const handleCheckboxChange = (event) => {
                    const { name, checked } = event.target;
                    handleChange({ target: { name, value: checked } });
                    setHasDelivery(checked)
                  };
                  return (

                    <Form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>

                      <div className="card" style={{ 'width': '80%' }}>
                        <div className="card-body text-end px-4 py-5">

                          <FormGroup >
                            <Input
                              id='text'
                              type="checkbox"
                              name="delivery"
                              invalid={errors.delivery && touched.delivery}
                              onChange={handleCheckboxChange}
                              onBlur={handleBlur}
                              value={values.delivery}
                            />
                            <Label id='text' for="delivery" style={{ marginLeft: '26px' }}>Incluir transporte</Label>
                            <FormFeedback>{errors.delivery}</FormFeedback>
                          </FormGroup>
                          {values.delivery &&
                            <>
                              <FormGroup style={{ display: 'inline-flex', gap: '16px', alignItems: 'center', justifyContent:'end' }}>
                                <Label id='text' for="delivery_cost" >Costo de envio:</Label>
                                <Input
                                  id='text'
                                  type="text"
                                  name="delivery_cost"
                                  invalid={errors.delivery_cost && touched.delivery_cost}
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                  value={values.delivery?values.delivery_cost:'$0'}
                                  style={{ width: '50%' }}
                                />
                                <FormFeedback>{errors.delivery_cost}</FormFeedback>
                              </FormGroup>
                            </>

                          }

                          <h6 className="card-subtitle  mt-4" id='text'><strong>Subtotal:</strong> ${hasDelivery?(calculateTotal()-deliveryInt).toLocaleString('es-CO'):calculateTotal().toLocaleString('es-CO') }</h6>

                          <h5 className="card-title mt-2" id='price'><strong>Total:</strong> ${calculateTotal().toLocaleString('es-CO')}</h5>
                        </div>
                      </div>
                      <Button type='submit' className='btn btn-primary my-5' style={{ 'color': 'white', 'width': '80%' }} color="#D2691E" disabled={isSubmitting}>
                        {isSubmitting ? `CARGANDO...` : `FINALIZAR →`}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          :
          <div className='position-absolute top-50 start-50 translate-middle' style={{ marginLeft: '10%', width: '100%' }}>

            <div className='row justify-content-center align-items-center'>
              <div className='col-4  text-center'>
                <FontAwesomeIcon icon={faExclamationCircle} bounce style={{ color: '#D2691E', width: '100px', height: '100px' }} />
              </div>

              <div className='row justify-content-center align-items-center '>
                <div className='col-4 mt-5  text-center'>
                  <h5 id='subtitle'>Aun no has agregado ningún producto a tu cotización</h5>
                </div>
              </div>

            </div>
          </div>}
        <ModalWindow state={modalState} setState={setModalState}>
          {/* <CotizationConfirmComponent/> */}
          {/*clientCreated ? <CotizationConfirmComponent state={modalState} setState={setModalState} setCotizationCreated={setCotizationCreated} /> : <ClientDataComponent state={modalState} setState={setModalState} setClientCreated={setClientCreated} />*/}
          {pdf ? <PDFViewerComponent pdf={pdfUrl}/> :cotizationCreated ? <CotizationPrintComponent state={modalState} setState={setModalState} setPDF={setPDF} setPdfUrl={setPdfUrl}/> 
          : clientCreated ? <CotizationConfirmComponent state={modalState} setState={setModalState} setCotizationCreated={setCotizationCreated} /> 
          : <ClientDataComponent state={modalState} setState={setModalState} setClientCreated={setClientCreated} />}
        </ModalWindow>

        
      </div>
    </>
  )


}

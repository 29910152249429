import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDocument } from 'pdf-lib';
import swal from 'sweetalert';
import { clear, setDescription, setEmployee, setNumber, setPayment } from '../../features/cotizations/cotizationDataSlice';
import { Formik } from 'formik';
import { Container, Button, Form, FormGroup, Label, Input, Card, FormFeedback, Col, Row } from "reactstrap";
import { faCreditCard, faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export default function CotizationConfirmComponent({ state, setState, setCotizationCreated }) {


    const cotizationData = useSelector(state => state.cotizationData)
    const dispatch = useDispatch()
    const [checkedState, setCheckedState] = useState({
        cash: false,
        card: false,
        bank_transfer: false,
    });

    const URI = "http://179.43.127.207:8000/quotes/newQuote"

    const saveCotization = async () => {
        await axios.post(URI, cotizationData)
            .then(function (res) {
                if (res.status === 200) {
                    swal({
                        title: "Cotización registrada con éxito:",
                        icon: "success",
                        timer: 3000,
                    }).then(() => {
                        setCotizationCreated(true)
                        dispatch(setNumber(res.data.quotation_num))
                    })
                }
            })
    }

    const validate = (values) => {
        const errors = {};
        if (values.description.length > 500) {
            errors.description = 'Máximo puede contener 500 caracteres'
        }
        if (values.employee.length > 50) {
            errors.employee = 'Máximo puede contener 50 caracteres'
        }
        return errors;
    }

    return (
        <>

            <div className='container'>
                <div className='row m-3'>
                    <h3 id='subtitle'>RESUMEN DE TU COTIZACIÓN</h3>
                </div>
                <div className='row'>
                    <div className='col-8'>
                        <div className='row mx-4'>
                            <div className="card p-0" style={{ width: '100%' }}>
                                <div className="card-header " style={{ background: '#D2691E', color: 'white', borderRadius: '0' }}>
                                    DATOS DEL CLIENTE
                                </div>
                                <div className="card-body">
                                    <blockquote className="blockquote mb-0">
                                        <p id='text' className='m-0'><strong>Nombre: </strong>{cotizationData.client.nombres_cliente + ' ' + cotizationData.client.apellidos_cliente}</p>
                                        <p id='text' className='m-0'><strong>Número de documento: </strong>{cotizationData.client.numero_documento}</p>
                                        <p id='text' className='m-0'><strong>Teléfono: </strong>{cotizationData.client.telefono}</p>
                                        <p id='text' className='m-0'><strong>Correo electrónico: </strong>{cotizationData.client.email}</p>
                                    </blockquote>
                                </div>
                            </div>

                        </div>
                        <div className='row overflow-auto m-4' style={{ maxHeight: '300px' }}>
                            <table className="table " style={{ width: '100%' }}>
                                <thead>
                                    <tr className='text-center' style={{ background: '#D2691E', color: 'white' }}>
                                        <th scope="col">Producto</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Precio unitario</th>
                                        <th scope="col">Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cotizationData.products.map((productItem, index) => {
                                        return (
                                            <tr>
                                                {/* <th scope="row"></th> */}
                                                <td>{productItem.product.nombre_producto}</td>
                                                <td className='text-center'>{productItem.quantity}</td>
                                                <td className='text-end'>$ {productItem.product.precio_unitario.toLocaleString('es-CO')}</td>
                                                <td className='text-end'>$ {productItem.subtotal.toLocaleString('es-CO')}</td>
                                            </tr>)
                                    })}


                                </tbody>
                            </table>
                        </div>
                        <div className='row mx-4'>

                            <div className='card text-end p-3'>
                                <p id='text' className='m-0'><strong>Total:</strong> $ {cotizationData.total.toLocaleString('es-CO')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 px-0'>
                        <Formik
                            initialValues={{
                                description: "",
                                employee: ""
                            }}
                            onSubmit={async (values) => {
                                dispatch(setEmployee(values.employee));
                                dispatch(setDescription(values.description));
                                dispatch(setPayment(checkedState.cash ? 'EF' : checkedState.card ? 'TR' : checkedState.bank_transfer ? 'TB' : 'NA'));
                                try {
                                    const res = await axios.post(URI, {
                                        ...cotizationData,
                                        description: values.description,
                                        employee: values.employee,
                                        payment_method: checkedState.cash ? 'EF' : checkedState.card ? 'TR' : checkedState.bank_transfer ? 'TB' : 'NA'
                                    });
                                    if (res.status === 200) {
                                        swal({
                                            title: "Cotización registrada con éxito:",
                                            icon: "success",
                                            timer: 3000,
                                        }).then(() => {
                                            setCotizationCreated(true);
                                            dispatch(setNumber(res.data.quotation_num));
                                        });
                                    }
                                } catch (error) {
                                    // Manejo de errores
                                    console.error("Error al enviar la petición:", error);
                                }
                            }}

                            validate={validate}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                } = props;

                                const handleCheckboxChange = (name) => {
                                    setCheckedState((prevState) => {
                                        const newState = { ...prevState };
                                        newState[name] = true;
                                        for (const key in newState) {
                                            if (key !== name) {
                                                newState[key] = false;
                                            }
                                        }
                                        return newState;
                                    });
                                };


                                return (

                                    <Form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>

                                        <div className='row mx-4'>

                                            <div className="card my-2" style={{ 'width': '100%' }}>
                                                <div className="card-body text-start px-4 py-3">
                                                    <FormGroup style={{}}>
                                                        <Label id='text' for="employee" >Vendedor:</Label>
                                                        <Input
                                                            id='text'
                                                            type="text"
                                                            name="employee"
                                                            invalid={errors.employee && touched.employee}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.employee}
                                                        />
                                                        <FormFeedback>{errors.employee}</FormFeedback>
                                                    </FormGroup>
                                                    <FormGroup style={{}}>
                                                        <Label id='text' for="description" >Observaciones:</Label>
                                                        <Input
                                                            id='text'
                                                            type="textarea"
                                                            name="description"
                                                            invalid={errors.description && touched.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.description}
                                                        />
                                                        <FormFeedback>{errors.description}</FormFeedback>
                                                    </FormGroup>

                                                </div>
                                            </div>

                                            <div className="card my-2" style={{ 'width': '100%' }}>
                                                <div className='card-header' style={{ background: 'none' }}>
                                                    <h5 id='subtitle' style={{ margin: '0' }}>MÉTODO DE PAGO</h5>
                                                </div>
                                                <div className="card-body text-start px-4 py-3">
                                                    <FormGroup style={{}}>
                                                        <Input
                                                            id='text'
                                                            type="checkbox"
                                                            name="cash"
                                                            invalid={errors.cash && touched.cash}
                                                            checked={checkedState.cash}
                                                            onChange={() => {
                                                                handleCheckboxChange("cash");
                                                                handleChange({ target: { name: "cash", value: !checkedState.cash } });
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        <Label id='text' for="cash" style={{ marginLeft: '16px' }}><FontAwesomeIcon icon={faMoneyBill1} size='2xl' style={{ color: '#d2691e', marginRight: '16px' }} />Efectivo</Label>
                                                        <FormFeedback>{errors.cash}</FormFeedback>
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Input
                                                            id='text'
                                                            type="checkbox"
                                                            name="card"
                                                            invalid={errors.card && touched.card}
                                                            checked={checkedState.card}
                                                            onChange={() => {
                                                                handleCheckboxChange("card");
                                                                handleChange({ target: { name: "card", value: !checkedState.card } });
                                                            }}
                                                            onBlur={handleBlur}

                                                        />
                                                        <Label id='text' for="card" style={{ marginLeft: '16px' }}><FontAwesomeIcon icon={faCreditCard} size='2xl' style={{ color: '#d2691e', marginRight: '16px' }} />Tarjeta  </Label>
                                                        <FormFeedback>{errors.card}</FormFeedback>

                                                    </FormGroup>
                                                    

                                                        <FormGroup >
                                                            <Input
                                                                id='text'
                                                                type="checkbox"
                                                                name="bank_transfer"
                                                                invalid={errors.bank_transfer && touched.bank_transfer}
                                                                checked={checkedState.bank_transfer}
                                                                onChange={() => {
                                                                    handleCheckboxChange("bank_transfer");
                                                                    handleChange({ target: { name: "bank_transfer", value: !checkedState.bank_transfer } });
                                                                }}
                                                                onBlur={handleBlur}

                                                            />
                                                            <Label id='text' for="bank_transfer" style={{ marginLeft: '16px', }}> <FontAwesomeIcon icon={faMoneyBillTransfer} size='2xl' style={{ color: '#d2691e', marginRight: '16px' }} />Transferencia </Label>
                                                            <FormFeedback>{errors.bank_transfer}</FormFeedback>
                                                        </FormGroup>
                                              


                                                </div>
                                            </div>



                                            <Button type='submit' className='btn btn-primary my-4' style={{ 'color': 'white', width: '100%' }} color="#D2691E" disabled={isSubmitting}>
                                                {isSubmitting ? `CARGANDO...` : `CONFIRMAR →`}
                                            </Button>


                                        </div>


                                        {/* <div className='row align-items-center justify-content-center my-2 text-center'>
                    <div className='col-12 text-center'>
                    <Button type='submit' className='btn btn-primary my-2' style={{ 'color': 'white', minWidth: '220px' }} color="#D2691E" disabled={isSubmitting}>
                        {isSubmitting ? `CARGANDO...` : `CONFIRMAR →`}
                    </Button> */}
                                        {/* <button className='btn btn-primary' style={{ color: 'white', minWidth: '220px' }} onClick={() => { saveCotization() }}> CONFIRMAR →</button> */}
                                        {/* </div>
                </div> */}

                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>





            </div>
        </>
    )
}

import { createSlice } from "@reduxjs/toolkit";

export const cotizationDataSlice = createSlice({
    name: 'cotizationData',
    initialState: {
        'client': {
            'id_cliente': 0,
            'nombres_cliente': '',
            'apellidos_cliente': '',
            'tipo_documento': '',
            'numero_documento': '',
            'telefono':'',
            'email': ''
        },
        'products': [],
        'quotation_num': 'COT-000000',
        'date': '',
        'description':'',
        'delivery_cost':0,
        'hasDelivery': false,
        'payment_method': "",
        'employee':"",
        'subtotal': 0,
        'total': 0,
        "pdfUrl": ''
    },
    reducers: {
        addNewProduct: (state, action) => {
            const index = state.products.map(productItem => productItem.product.id_product).indexOf(action.payload.product.id_product)
            if (index === -1) {

                state.products.push(action.payload)
            }else{
                state.products[index].quantity += action.payload.quantity
            }
        },
        deleteProduct: (state, action) => {

            const index = state.products.map(productItem => productItem.product.id_product).indexOf(action.payload.id_product)
           

            state.products.splice(index, 1)

        },
        setTotal(state,action){
            state.total = action.payload
        },
        setDescription(state, action){
            state.description = action.payload
        },
        setDeliveryCost(state, action){
            state.delivery_cost = action.payload
        },
        setDeliveryStatus(state, action){
            state.hasDelivery = action.payload
        },
        setClientData(state,action){
            state.client = action.payload
        },
        setDate(state, action){
            state.date = action.payload
        },
        setNumber(state, action){
            state.quotation_num = action.payload
        },
        setPayment(state, action){
            state.payment_method = action.payload
        },
        setEmployee(state, action){
            state.employee = action.payload
        },
        setSubtotal(state, action){
            state.subtotal = action.payload
        },
        setPDF(state, action){
            state.pdfUrl = action.payload
        },
        // todo...
        clear(state, action){
            state.client = action.payload.client
            state.products = action.payload.products
            state.description = action.payload.description
            state.date = action.payload.date
            state.subtotal = action.payload.subtotal
            state.total = action.payload.total
            state.employee = action.payload.employee
            state.payment_method = action.payload.quotation_num
            state.hasDelivery = action.payload.hasDelivery
            state.delivery_cost = action.payload.delivery_cost
            state.pdfUrl = action.payload.pdfUrl
        }
    }
})

export const { deleteProduct,setPDF,setPayment, setEmployee, addNewProduct, setTotal, setDescription, setClientData,setDate,clear,setNumber, setDeliveryCost, setDeliveryStatus, setSubtotal} = cotizationDataSlice.actions

export default cotizationDataSlice.reducer
import './scss/custom.css'
import './App.css';
import Login from './components/Login';
import SignupForm from './components/SignupForm';

function App() {
  return (
   <Login/>
    // <SignupForm/>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function PDFViewerComponent({pdf}) {

    const [modalHeight, setModalHeight] = useState(window.innerHeight * 0.95); // Inicializa la altura del modal al 80% de la altura de la ventana

    useEffect(() => {
        const handleResize = () => {
            setModalHeight(window.innerHeight * 0.8); // Actualiza la altura del modal cuando la ventana se redimensiona
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

  return (
    <iframe title="PDF Viewer" src={pdf} width="800px" height={modalHeight }></iframe>
  )
}

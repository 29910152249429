import React from "react";
import iconMenu from "../components/img/user.png";
import UserList from "./userList";
import ProductList from "./productList";
import RegisterUser from "./registerUser";
import RegisterProductAux from "./registerProductAux";
import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import CategoryList from "./categoryList";
import ClientList from "./clientList";
import Quotes from "./quotes";
import Login from "./Login";

const Menu = ({ handleLogout }) => {
  function LogOut() {
    handleLogout();
    document.getElementById("caja_menu").style.display = "none";
    document.getElementById("form_login").style.display = "block";
    document.getElementById("txtusu").value = " ";
    document.getElementById("txtusu").focus();
  }

  return (
    <>
      <BrowserRouter >
        <div id="caja_menu" style={{ border: "8px solid #D2691E" }}>
          <nav
            className="navbar navbar-expand-md navbar-light"
            style={{ backgroundColor: "#D2691E" }}
          >
            <div className="container-fluid" style={{ backgroundColor: "#D2691E" }}>
              <Link className="navbar-brand" to="/home">
                <img src={iconMenu} style={{ width: "52px" }} />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                aria-controls="navbarSupportedContent"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ backgroundColor: "#D2691E" }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{ backgroundColor: "#D2691E" }}>


                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      id="subtitle"
                      aria-current="page"
                      to="/register"
                      style={{ color: "#FFFFF3", textDecoration: "none", transition: "background-color 0.3s ease-in-out" }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = "#ba5106"}
                      onMouseLeave={(e) => e.target.style.backgroundColor = "#D2691E"}
                    >
                      Registrar Usuario
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      id="subtitle"
                      aria-current="page"
                      to="/list"
                      style={{ color: "#FFFFF3", textDecoration: "none", transition: "background-color 0.3s ease-in-out" }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = "#ba5106"}
                      onMouseLeave={(e) => e.target.style.backgroundColor = "#D2691E"}
                    >
                      Usuarios
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      id="subtitle"
                      aria-current="page"
                      to="/registerProduct"
                      style={{ color: "#FFFFF3", textDecoration: "none", transition: "background-color 0.3s ease-in-out" }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = "#ba5106"}
                      onMouseLeave={(e) => e.target.style.backgroundColor = "#D2691E"}
                    >
                      Registrar Producto
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      id="subtitle"
                      aria-current="page"
                      to="/productos"
                      style={{ color: "#FFFFF3", textDecoration: "none", transition: "background-color 0.3s ease-in-out" }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = "#ba5106"}
                      onMouseLeave={(e) => e.target.style.backgroundColor = "#D2691E"}
                    >
                      Productos
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      id="subtitle"
                      aria-current="page"
                      to="/categorias"
                      style={{ color: "#FFFFF3", textDecoration: "none", transition: "background-color 0.3s ease-in-out" }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = "#ba5106"}
                      onMouseLeave={(e) => e.target.style.backgroundColor = "#D2691E"}
                    >
                      Categorias
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      id="subtitle"
                      className="nav-link"
                      aria-current="page"
                      to="/cotizaciones"
                      style={{ color: "#FFFFF3", textDecoration: "none", transition: "background-color 0.3s ease-in-out" }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = "#ba5106"}
                      onMouseLeave={(e) => e.target.style.backgroundColor = "#D2691E"}
                    >
                      Cotizaciones
                    </Link>
                  </li >
                  <li className="nav-item" style={{ backgroundColor: "#D2691E" }}>
                    <Link
                      id="subtitle"
                      className="nav-link"
                      aria-current="page"
                      to="/clientes"
                      style={{ color: "#FFFFF3", textDecoration: "none", transition: "background-color 0.3s ease-in-out" }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = "#ba5106"}
                      onMouseLeave={(e) => e.target.style.backgroundColor = "#D2691E"}
                    >
                      Clientes
                    </Link>
                  </li >
                </ul>
                <span className="navbar-text" style={{ backgroundColor: "#D2691E" }}>
                  <button
                    className="btn btn-outline-success"
                    onClick={LogOut}
                    style={{ color: "#FFFFF3", textDecoration: "none", transition: "background-color 0.3s ease-in-out" }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = "##4C463D"}
                    onMouseLeave={(e) => e.target.style.backgroundColor = "#fa6b05"}
                    id="subtitle"
                  >
                    Cerrar sesión
                  </button>
                </span>
              </div>
            </div>
          </nav>
        </div>
        <Routes>
          <Route path="/register" element={<RegisterUser />}></Route>
          <Route path="/list" element={<UserList />}></Route>
          <Route path="/registerProduct" element={<RegisterProductAux />}></Route>
          <Route path="/productos" element={<ProductList />}></Route>
          <Route path="/categorias" element={<CategoryList />}></Route>
          <Route path="/cotizaciones" element={<Quotes />}></Route>
          <Route path="/clientes" element={<ClientList />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Menu;
